<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form :model="formData" ref="form" label-width="120px" size="mini" v-loading="loading">
      <el-form-item prop="userName" label="会员昵称">
        <div> {{formData.userName}} </div>
      </el-form-item>
      <el-form-item prop="contentText" label="内容文字">
        <div> {{formData.contentText}} </div>
      </el-form-item>
      <el-form-item prop="contentImages" label="内容图片">
        <div style="display:flex;">
          <div v-for="(item,index) of formData.contentImages" :key="index" style="margin-right:10px;margin-bottom:10px;">
            <el-image
              style="width:100px;height:100px;"
              :src="item"
              fit="cover"
              :preview-src-list="formData.contentImages"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="commentNum" label="评论数">
        <div> {{formData.commentNum}} </div>
      </el-form-item>
      <el-form-item prop="giveLikeNum" label="点赞数">
        <div> {{formData.giveLikeNum}} </div>
      </el-form-item>
      <el-form-item prop="glanceNum" label="浏览数">
        <div> {{formData.glanceNum}} </div>
      </el-form-item>
      <el-form-item prop="show" label="是否显示">
        <div> {{formData.show}} </div>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.getBanner()
      },
    }
  },
  methods: {
    async getBanner(){
      this.loading = true
      
      this.formData = {
        userName: '我是会员昵称',
        contentText: '我是内容文字',
        contentImages: ['https://hq-one-stand.oss-cn-shenzhen.aliyuncs.com/coox/category/1669185217450.jpg','https://hq-one-stand.oss-cn-shenzhen.aliyuncs.com/coox/category/1669185217450.jpg'],
        commentNum: '30',
        giveLikeNum: '20',
        glanceNum: '50',
        show: '1',
      }
      setTimeout(() => this.loading = false, 1000)
    },
    handleClose(){
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>