<template>
  <div>
    <gl-title :title="$route.meta.title"></gl-title>
    <gl-page-header>
      <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
        <el-form-item prop="userName" label="会员昵称">
          <gl-list-search v-model="mFormData.userName" placeholder="会员昵称"></gl-list-search>
        </el-form-item>
        <el-form-item prop="startTime" label="发布时间">
          <gl-date-picker :startTime.sync="mFormData.startTime" :endTime.sync="mFormData.endTime"></gl-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" icon="el-icon-search" @click="mReload"> 搜索 </el-button>
          <el-button icon="el-icon-refresh" @click="mResetForm('listForm')"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </gl-page-header>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" min-width="30"></el-table-column>
        <el-table-column prop="userName" label="会员昵称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="contentPreview" label="内容预览"></el-table-column>
        <el-table-column prop="conmentNum" label="评论数"></el-table-column>
        <el-table-column prop="conmentNum" label="点赞数"></el-table-column>
        <el-table-column prop="conmentNum" label="浏览数"></el-table-column>
        <el-table-column prop="agreementContent" label="发布时间"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="mClickAction('mPreviewVisible', scope.row.id)"> 查看 </el-button>
            <el-button type="text" @click="mDelete(scope.row.id)"> 删除 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <preview :visible.sync="mPreviewVisible" :itemId="mItemId"/>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import preview from './preview.vue'
export default {
  components: {preview},
  mixins: [ListMixin],
  mounted(){
    this.mGetListFun 
    this.mDeleteFun = (time = 1000) => new Promise(resolve => setTimeout(() => resolve({code: 200, msg: '炒作成功'}), time))
    this.mGetList()
  },
}
</script>

<style>

</style>